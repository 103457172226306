<template>
<div class="app-container with-content-offset-top with-content-offset-bottom">

  <h1 class="app-reading-title with-aside">Відео</h1>

  <Archive :items="appSettings.videos" aspect-ratio="1.778" />

</div>
</template>

<script>
import Archive from '@/components/Archive';
export default {
  components: { Archive },
  created: function () {
    this.setPageMetadata('Відео')
  }
}
</script>
