<template>
<v-container fluid pa-0>
  <v-row v-for="(group, j) in itemGroups" :key="j" :no-gutters="flat">
    <v-col v-if="group.name" cols="12" class="my-5">
      <h2>{{ group.name }}</h2>
      <span v-if="group.hint" class="group-name-hint">{{ group.hint }}</span>
    </v-col>
    <v-col v-for="({ name, hint, image, link, disabled }, i) in groupItems(group)" :key="i"
      :cols="size('cols', i)" :sm="size('sm', i)" :md="size('md', i)" :lg="size('lg', i)">
      <v-card elevation="4" :tile="flat" target="blank"
        :to  ="!disabled && link && !link.startsWith('http') ? link : ''"
        :href="!disabled && link && link.startsWith('http') ? link : ''">
        <v-img :src="image || fallbackImage || '/img/default-thumb.png'" class="card-image white--text align-end" :class="{ 'disabled': disabled }" :aspect-ratio="aspectRatio">
          <div class="shade"></div>
          <v-card-title class="card-title">
            <div class="hint">{{ hint }}</div>
            <div>{{ name }}</div>
          </v-card-title>
        </v-img>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    fallbackImage: { type: String },
    aspectRatio: { type: String, default: "2" },
    flat: { type: Boolean, default: true },
    cols: { type: String, default: "12" },
    sm: { type: String, default: "6" },
    md: { type: String, default: "4" },
    lg: { type: String, default: "3" }
  },
  computed: {
    itemGroups() {
      return this.items.reduce((carry, { group }) => {
        if (typeof(group) != 'object') {
          group = { name: group || '' }
        }

        if (!carry.find(e => e.name == group.name)) {
          carry.push(group)
        }

        return carry
      }, [])
    }
  },
  methods: {
    size: function (view, index) {
      const seq = this[view].split('/')
      const idx = Math.min(seq.length - 1, index)
      return seq[idx]
    },
    groupItems: function (filterGroup) {
      return this.items.filter(({ group }) => {
        if (typeof(group) != 'object') {
          group = { name: group || '' }
        }
        return group.name == filterGroup.name
      })
    }
  }
}
</script>

<style scoped>

.group-name-hint {
  color: #999;
}

.card-image.disabled {
  filter: grayscale(100%);
}

.card-image .shade {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, transparent 80px);
  z-index: -1;
}

.card-title {
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  padding-top: 0;
  max-width: 92%;
  font: var(--font-text-bold-extra);
  font-size: 140%;
  line-height: 1.1;
  word-break: normal;
  text-shadow: 1px 1px 3px black;
}

.card-title .hint {
  padding-bottom: 0.2em;
  font-size: 70%;
  opacity: .65;
}

a {
  transition: opacity .2s ease-out;
}

</style>
